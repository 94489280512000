.falling-leaves-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
}

.leaf {
    position: absolute;
    top: -10%;
    width: 40px;
    height: 40px;
    background-image: url('../assets/leaf.png'); /* Ensure the path is correct */
    background-size: cover;
    animation: sway-fall var(--random-duration, 10s) ease-in-out infinite;
    animation-delay: var(--random-delay, 0s);
    transform-origin: center;
}

/* Unified animation for falling, swaying, and rotating */
@keyframes sway-fall {
    0% {
        transform: translateX(0) translateY(0) rotate(0deg);
    }
    25% {
        transform: translateX(-30px) translateY(25vh) rotate(-15deg);
    }
    50% {
        transform: translateX(30px) translateY(50vh) rotate(15deg);
    }
    75% {
        transform: translateX(-20px) translateY(75vh) rotate(-10deg);
    }
    100% {
        transform: translateX(0) translateY(110vh) rotate(0deg);
    }
}
